
import {
  validatenull,
} from './validate';
import Vue from 'vue';
import CryptoJS from 'crypto-js'

export const initMenu = (router, menu) => {
  if (menu.length === 0) {
    return;
  }
  router.addRoutes(formatRoutes(menu));
};

/**
 * Get the first item that pass the test
 * by second argument function
 *
 * @param {Array} list
 * @param {Function} f
 * @return {*}
 */
export function find (list, f) {
  return list.filter(f)[0]
}

/**
 * Deep copy the given object considering circular structure.
 * This function caches all nested objects and its copies.
 * If it detects circular structure, use cached copy to avoid infinite loop.
 *
 * @param {*} obj
 * @param {Array<Object>} cache
 * @return {*}
 */
export function deepCopy (obj, cache = []) {
  // just return if obj is immutable value
  if (obj === null || typeof obj !== 'object') {
    return obj
  }

  // if obj is hit, it is in circular structure
  const hit = find(cache, c => c.original === obj)
  if (hit) {
    return hit.copy
  }

  const copy = Array.isArray(obj) ? [] : {}
  // put the copy into cache at first
  // because we want to refer it in recursive deepCopy
  cache.push({
    original: obj,
    copy
  })

  Object.keys(obj).forEach(key => {
    copy[key] = deepCopy(obj[key], cache)
  })

  return copy
}

/**
 * 获取有权限的菜单
 */
export const getAuthorizedMenu = (menu, pmsSet, type = 'menu') => {
  if (!menu) return;
  const authorizedMenu = [];
  for (let i = 0; i < menu.length; i++) {
    const subMenus = menu[i].children;
    if(!menu[i].type) {
      menu[i].type = 'menu'
    }
    if (menu[i].type === type) {
      // 菜单无法点击并且存在子节点
      if (subMenus && subMenus.length > 0) {
        // 将子节点替换为有权限的子节点
        menu[i].children = getAuthorizedMenu(subMenus, pmsSet);
        if (menu[i].children.length > 0) {
          authorizedMenu.push(menu[i]);
        }
        // 菜单可以点击，有权限访问，并且不存在子节点
      } else if (pmsSet.has(menu[i].permission) && !subMenus) {
        authorizedMenu.push(menu[i]);
      }
    }
  }

  // 返回有权限的子节点
  return authorizedMenu;
};

export const downloadFile = (resp) => {
  const data = resp.data;
  let filename = resp.headers['content-disposition'].substring(resp.headers['content-disposition'].indexOf('fileName=') + 'fileName='.length);
  filename = decodeURIComponent(filename);
  const blob = new Blob([data], {
    type: 'application/octet-stream',
  });
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const blobURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank');
    }
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(blobURL);
  }
};
export const downloadPdfFile = (resp) => {
  const data = resp.data;
  let filename = resp.headers['content-disposition'].substring(resp.headers['content-disposition'].indexOf('fileName=') + 'fileName='.length);
  filename = decodeURIComponent(filename);
  const blob = new Blob([data], {
    type: 'application/octet-stream',
  });
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const blobURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank');
    }
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(blobURL);
  }
};

const percent2percent25 = (URI) => {
  if(URI.indexOf('%') > -1) {
    return URI.replace(/%/g,'%25')
  }else{
    return URI;
  }
}

/**
 * 获取树指定节点的父id
 * @param pId
 * @param treeData
 * @returns {*}
 */
export const getTreePid = (pId, treeData) => {
  let temp = null;
  getPid(null, treeData);
  return temp;
  function getPid(pNode, cNode) {
    if (cNode.id === pId) {
      return temp = pNode;
    }
    if (cNode.children) {
      for (let i = 0; i < cNode.children.length; i++) {
        getPid(cNode, cNode.children[i]);
      }
    }
  }
};

/**
 * 获取当前节点所有子节点id
 * @param treeData
 * @returns {Array}
 */
export const getTreeChildrenId = (treeData) => {
  const temp = [];
  temp.push(treeData.id);
  getPid(treeData);
  return temp;
  function getPid(node) {
    if (node.children.length == 0) {
      return;
    }
    for (let i = 0; i < node.children.length; i++) {
      temp.push(node.children[i].id);
      getPid(node.children[i]);
    }
  }
};

/**
 * 从根节点开始获取当前节点所有父节点id
 * @param treeData
 * @returns {Array}
 */
export const getTreeAllPid = (treeData, nodeId) => {
  let temp = [];
  let isEnd = false;
  const id = `${nodeId}`;
  for (let i = 0; i < treeData.length; i++) {
    if (isEnd) break;
    temp = [];
    getPid(treeData[i]);
  }
  return isEnd ? temp : [];
  function getPid(node) {
    temp.push(node.id);
    if (node.id === id) {
      isEnd = true;

      // temp.push(node.id)
    } else if (!isEnd) {

      if (!node.children || node.children.length === 0) {
        // 叶子节点删除数据
        temp.pop();
      } else {
        for (let i = 0; i < node.children.length; i++) {
          if (isEnd) break;
          // temp.push(node.id)
          getPid(node.children[i]);
          if (!isEnd && i !== 0 && i === node.children.length - 1) {
            // 当前所有子节点没有找到匹配数据，返回上一层级
            temp.pop();
          }
        }
      }
    }
  }
};

/**
 * 清除叶子节点所有空Children属性
 * @param treeData
 * @returns {Array}
 */
export const clearEmptyTreeChildren = (treeData) => {
  // const temp = []
  // temp.push(treeData.id)
  treeData.forEach((data) => {
    getPid(data);
  });
  // getPid(treeData)
  // return temp
  function getPid(node) {
    if (node.children.length === 0) {
      Vue.delete(node, 'children');
      return;
    }
    for (let i = 0; i < node.children.length; i++) {
      // temp.push(node.children[i].id)
      getPid(node.children[i]);
    }
  }
};
export const formatRoutes = (aMenu) => {

  const aRouter = [];
  aMenu.forEach((oMenu) => {
    const {
      path,
      component,
      name,
      icon,
      children,
    } = oMenu;
    if (!validatenull(component)) {
      let filePath;
      const oRouter = {
        path,
        component(resolve) {
          let componentPath = '';
          if (component === 'Layout') {
            require(['../page/index'], resolve);
            // () => import('../page/index')
            return;
          }
          componentPath = component;

          // () => import(`../${componentPath}.vue`)
          require([`../${componentPath}.vue`], resolve);
        },
        name,
        icon,
        children: validatenull(children) ? [] : formatRoutes(children),
      };
      aRouter.push(oRouter);
    }
  });
  return aRouter;
};

/**
 * 加密处理
 */
export const encryption = (params) => {
  let {
    data,
    type,
    param,
    key,
  } = params;
  const result = JSON.parse(JSON.stringify(data));
  if (type === 'Base64') {
    param.forEach((ele) => {
      result[ele] = btoa(result[ele]);
    });
  } else {
    param.forEach((ele) => {
      const data = result[ele];
      key = CryptoJS.enc.Latin1.parse(key);
      const iv = key;
      // 加密
      const encrypted = CryptoJS.AES.encrypt(
        data,
        key,
        { iv,
          momoblede: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.ZeroPadding,
        });
      result[ele] = encrypted.toString();
    });
  }
  return result;
};

/**
 * 设置浏览器头部标题
 */
export const setTitle = function (title) {
  title = title ? `${title}` : '主薪谷';
  window.document.title = title;
};
/**
 * 浏览器判断是否全屏
 */
export const fullscreenToggel = () => {
  if (fullscreenEnable()) {
    exitFullScreen();
  } else {
    reqFullScreen();
  }
};
/**
 * esc监听全屏
 */
export const listenfullscreen = (callback) => {
  function listen() {
    callback();
  }
  document.addEventListener('fullscreenchange', (e) => {
    listen();
  });
  document.addEventListener('mozfullscreenchange', (e) => {
    listen();
  });
  document.addEventListener('webkitfullscreenchange', (e) => {
    listen();
  });
  document.addEventListener('msfullscreenchange', (e) => {
    listen();
  });
};

/**
 * 浏览器判断是否全屏
 */
export const fullscreenEnable = () => {
  return document.fullscreenElement    ||
    document.msFullscreenElement  ||
    document.mozFullScreenElement ||
    document.webkitFullscreenElement || false;
};

/**
 * 浏览器全屏
 */
export const reqFullScreen = () => {
  if (document.documentElement.requestFullScreen) {
    document.documentElement.requestFullScreen();
  } else if (document.documentElement.webkitRequestFullScreen) {
    document.documentElement.webkitRequestFullScreen();
  } else if (document.documentElement.mozRequestFullScreen) {
    document.documentElement.mozRequestFullScreen();
  }
};

/**
 * 浏览器退出全屏
 */
export const exitFullScreen = () => {
  if (document.documentElement.requestFullScreen) {
    document.exitFullScreen();
  } else if (document.documentElement.webkitRequestFullScreen) {
    document.webkitCancelFullScreen();
  } else if (document.documentElement.mozRequestFullScreen) {
    document.mozCancelFullScreen();
  }
};

/**
 * 递归寻找子类的父类
 */
export const findParent = (menu, id) => {
  for (let i = 0; i < menu.length; i++) {
    if (menu[i].children.length !== 0) {
      for (let j = 0; j < menu[i].children.length; j++) {
        if (menu[i].children[j].id === id) {
          return menu[i];
        }
        if (menu[i].children[j].children.length !== 0) {
          return findParent(menu[i].children[j].children, id);
        }
      }
    }
  }
};

/**
 * 总体路由处理器
 */
export const resolveUrlPath = (url, name) => {
  let reqUrl = url;
  // if (url.indexOf('#') !== -1 && url.indexOf('http') === -1) {
  //   const port = reqUrl.substr(reqUrl.indexOf(':'));
  //   reqUrl = `/myiframe/urlPath?src=${baseUrl}${port}${reqUrl.replace('#', '').replace(port, '')}}&name=${name}`;
  // } else if (url.indexOf('http') !== -1) {
  //   reqUrl = `/myiframe/urlPath?src=${reqUrl}&name=${name}`;
  // } else {
  //   reqUrl = `${reqUrl}`;
  // }
  return reqUrl;
};

/**
 * 总体路由设置器
 */
export const setUrlPath = ($route) => {
  let value = '';
  if ($route.query.src) {
    value = $route.query.src;
  } else {
    value = $route.path;
  }
  return value;
};

/**
 * 动态插入css
 */
export const loadStyle = (url) => {
  const link = document.createElement('link');
  link.type = 'text/css';
  link.rel = 'stylesheet';
  link.href = url;
  const head = document.getElementsByTagName('head')[0];
  head.appendChild(link);
};

/**
 * 根据字典的value显示label
 */
export const findByvalue = (dic, value) => {
  let result = '';
  if (validatenull(dic)) return value;
  if (typeof (value) === 'string' || typeof (value) === 'number') {
    let index = 0;
    index = findArray(dic, value);
    if (index !== -1) {
      result = dic[index].label;
    } else {
      result = value;
    }
  } else if (value instanceof Array) {
    result = [];
    let index = 0;
    value.forEach((ele) => {
      index = findArray(dic, ele);
      if (index !== -1) {
        result.push(dic[index].label);
      } else {
        result.push(value);
      }
    });
    result = result.toString();
  }
  return result;
};

/**
 * 根据字典的value查找对应的index
 */
export const findArray = (dic, value) => {
  for (let i = 0; i < dic.length; i++) {
    if (dic[i].value === value) {
      return i;
    }
  }
  return -1;
};

/**
 * 生成随机len位数字
 */
export const randomLenNum = (len, date) => {
  let random = '';
  random = Math.ceil(Math.random() * 100000000000000).toString().substr(0, typeof len === 'number' ? len : 4);
  if (date) random += Date.now();
  return random;
};

/**
 * 浮点数相加(两位)
 */
export const floatSum = (values) => {
  let sum = 0;
  for(var value in values){
    sum += Math.round(parseFloat(values[value])*100)
  }
  sum = sum/100;
  return parseFloat(sum.toFixed(2));
};

/**
 * 浮点数相减(两位)
 */
export const floatSub = (total,values) => {
  let sub = total*100;
  sub = sub.toFixed(2);
  for(var value in values){
    sub -= Math.round(parseFloat(values[value])*100)
  }
  sub = sub/100;
  return parseFloat(sub.toFixed(2));
};

/**
 * 时间格式转换
 */
export function convertTimeForm (date){
  //date是传入的时间
  let d = new Date(date);
  let month = (d.getMonth() + 1) < 10 ? '0'+(d.getMonth() + 1) : (d.getMonth() + 1);
  let day = d.getDate()<10 ? '0'+d.getDate() : d.getDate();
  let hours = d.getHours()<10 ? '0'+d.getHours() : d.getHours();
  let min = d.getMinutes()<10 ? '0'+d.getMinutes() : d.getMinutes();
  let sec = d.getSeconds()<10 ? '0'+d.getSeconds() : d.getSeconds();

  let times=d.getFullYear() + '-' + month + '-' + day + ' ' + hours + ':' + min + ':' + sec;
  return times
}

/**
 * 时间格式转换 YYY-MM-dd
 */
 export function convertDateForm (date){
  //date是传入的时间
  let d = new Date(date);
  let month = (d.getMonth() + 1) < 10 ? '0'+(d.getMonth() + 1) : (d.getMonth() + 1);
  let day = d.getDate()<10 ? '0'+d.getDate() : d.getDate();

  let times=d.getFullYear() + '-' + month + '-' + day;
  return times
}

/**
 * 两位浮点数输入格式化
 */
export function formatFloat(val) {
  val = val+""
  if(val){
    const temp = val;
    // 限制两位小数数字
    val = val.replace(/[^\d.]/g,"");  //清除“数字”和“.”以外的字符
    if(val){
      val = val.replace(/\.{2,}/g,"."); //只保留第一个. 清除多余的
      val = val.replace(".","$#$").replace(/\./g,"").replace("$#$",".");

      val = val.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3');//只能输入两个小数

      // 10亿亿的会出现科学计数法，限制15位数字
      if(val.length > 15){
        val= val.substr(0,15)
      }

      if(val.indexOf(".")< 0){//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        val= parseFloat(val);
      }else if(val.indexOf(".")> 1 && val[0] === "0"){//控制0X.XX
        val= parseFloat(val);
      }else if(val.indexOf(".") === 0 ){//控制.XX
        val= parseFloat("0"+val);
      }

    }

    return val
  }
};

//判断元素是否有改class
export const hasClass = (element, className) =>{
  var reg = new RegExp('(\\s|^)'+className+'(\\s|$)');
  return element.className.match(reg);
};

//给元素加class
export const addClass = (element, className) =>{
  if (!hasClass(element, className)){
    element.className = element.className+ " "+className;
  }
};

//给元素移除class
export const removeClass = (element, className) =>{
  if (hasClass(element, className)) {
    var reg = new RegExp('(\\s|^)'+className+'(\\s|$)');
    element.className = element.className.replace(reg,' ');
  }
};

//aes 加密
export const encrypt = (word, keyStr) => {
  keyStr = keyStr ? keyStr : 'groupappEncrypk1';
  var key  = CryptoJS.enc.Utf8.parse(keyStr);//Latin1 w8m31+Yy/Nw6thPsMpO5fg==
  var srcs = CryptoJS.enc.Utf8.parse(word);
  var encrypted = CryptoJS.AES.encrypt(srcs, key, {mode:CryptoJS.mode.ECB,padding: CryptoJS.pad.Pkcs7});
  return encrypted.toString();
};
//aes 解密
export const decrypt = (word, keyStr) => {
  keyStr = keyStr ? keyStr : 'groupappEncrypk1';
  var key  = CryptoJS.enc.Utf8.parse(keyStr);//Latin1 w8m31+Yy/Nw6thPsMpO5fg==
  var decrypt = CryptoJS.AES.decrypt(word, key, {mode:CryptoJS.mode.ECB,padding: CryptoJS.pad.Pkcs7});
  return CryptoJS.enc.Utf8.stringify(decrypt).toString();
};

export function HashMap(){
  //定义长度
  var length = 0;
  //创建一个对象
  var obj = new Object();

  /**
   * 判断Map是否为空
   */
  this.isEmpty = function(){
    return length == 0;
  };

  /**
   * 判断对象中是否包含给定Key
   */
  this.containsKey=function(key){
    return (key in obj);
  };

  /**
   * 判断对象中是否包含给定的Value
   */
  this.containsValue=function(value){
    for(var key in obj){
      if(obj[key] == value){
        return true;
      }
    }
    return false;
  };

  /**
   *向map中添加数据
   */
  this.put=function(key,value){
    if(!this.containsKey(key)){
      length++;
    }
    obj[key] = value;
  };

  /**
   * 根据给定的Key获得Value
   */
  this.get=function(key){
    return this.containsKey(key)?obj[key]:null;
  };

  /**
   * 根据给定的Key删除一个值
   */
  this.remove=function(key){
    if(this.containsKey(key)&&(delete obj[key])){
      length--;
    }
  };

  /**
   * 获得Map中的所有Value
   */
  this.values=function(){
    var _values= new Array();
    for(var key in obj){
      _values.push(obj[key]);
    }
    return _values;
  };

  /**
   * 获得Map中的所有Key
   */
  this.keySet=function(){
    var _keys = new Array();
    for(var key in obj){
      _keys.push(key);
    }
    return _keys;
  };

  /**
   * 获得Map的长度
   */
  this.size = function(){
    return length;
  };

  /**
   * 清空Map
   */
  this.clear = function(){
    length = 0;
    obj = new Object();
  };
}

// YYYY-MM格式日期月份增减
export const monthCalcu = (monthStr, number) => {
  let now = new Date(monthStr);
  now.setMonth(now.getMonth() + number);
  let year = now.getFullYear(); //得到年份
  let month = now.getMonth(); //得到月份
  month = month + 1;
  month = month.toString().padStart(2, "0");
  return `${year}-${month}`
};
