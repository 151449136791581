<template>
  <div>
    <el-button type="primary"@click="openInvoiceType">点击打开发票拆分</el-button>
    <el-button type="button" @click="compare">对比两条数据</el-button>
    <el-dialog
      title="提示"

      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="invoiceOpen"
      :showClose="invoiceSelect?true:false"
      :width="invoiceSelect?'600px':'1000px'">

      <span slot="title" class="dialogTitle">
          <div class="tancengTitle"><span>发票拆分</span> <div class="backBtn" v-if="!invoiceSelect"
                                                           @click="backPrev">返回</div></div>
      </span>

      <invoiceType
        v-if="invoiceSelect"
        :invoiceActive="invoiceActive"
        @changeInvoiceType="changeInvoiceType"
      ></invoiceType>
      <div class="invoiceDetail">
        <invoiceTop
          v-if="!invoiceSelect"
          :invoice-type-name="invoiceData.invoiceTypeName"
          :invoice-company-name="invoiceData.invoiceCompanyName"
          :invoice-company-data="invoiceData.invoiceCompanyData"
          :invoice-active="invoiceActive"
          @insertEvent="insertEvent"
        ></invoiceTop>
        <invoiceTable
          ref="invoiceTable"
          v-if="!invoiceSelect"
          :invoice-active="invoiceActive"
          :names="invoiceData.splitLists[invoiceActive - 1].splitName"
          :datas="invoiceData.splitLists[invoiceActive - 1].splitList"
          :refname="invoiceData.invoiceRef"
          :sumItem="['total','xiaotax']"
          @backPrev="backPrev"
        ></invoiceTable>
      </div>

    </el-dialog>
  </div>
</template>

<script>
  import invoiceType from "./invoiceType";
  import invoiceTop from "./invoiceTop";
  import invoiceTable from "./invoiceTable";
  import { floatSum } from '@/util/util';

  export default {
    name: "invoiceDemo",
    components: { invoiceType, invoiceTop, invoiceTable },
    data() {
      return {
        invoiceOpen: false,//是否打开发票拆分
        invoiceActive: 1,//选中的拆分类型，默认为默认
        invoiceSelect: true,//选择拆分类型页
        invoiceData: {
          invoiceRef: "invoiceRef",
          invoiceTypeName: "默认发票抬头",//拆分类型名称
          invoiceCompanyName: "瑞士安美资源有限公司",//公司名称
          invoiceCompanyData: {
            companyNo: "403291",
            companyCount: "4",
            companyWage: "12,000.00",
            companyService: "1,000.00",
            companySales: "200.00"
          },
          splitLists: [{
            splitName: [
              { type: "index", title: "序号", width: 50 },
              { field: "number", width: "100px", title: "发票号" },
              { field: "item", width: "150px", title: "开票项目" },
              { field: "time", width: "150px", title: "开票月份", editRender: { name: "input", attrs: { type: "month" } } },
              { field: "type", width: "120px", title: "开票类型" },
              { field: "taitou", width: "200px", title: "发票抬头" },
              { field: "total", width: "100px", title: "票据总金额" },
              { field: "tax", width: "50px", title: "税率" },
              { field: "xiaotax", width: "50px", title: "销项税" },
              { field: "remark", width: "100px", title: "备注" }
            ],
            splitList: [
              {
                number: "201901001",
                item: "工资代收代付",
                time: "2019-10",
                type: "增值税普票",
                taitou: "瑞士安美资源有限公司",
                total: 12.5,
                tax: "",
                xiaotax: null,
                remark: ""
              },
              {
                number: "201901001",
                item: "工资代收代付",
                time: "2019-10",
                type: "增值税普票",
                taitou: "瑞士安美资源有限公司",
                total: 20,
                tax: "5%",
                xiaotax: null,
                remark: ""
              },
              {
                number: "201901001",
                item: "工资代收代付",
                time: "2019-10",
                type: "增值税普票",
                taitou: "瑞士安美资源有限公司",
                total: 0,
                tax: "5%",
                xiaotax: null,
                remark: ""
              },
              {
                number: "201901001",
                item: "工资代收代付",
                time: "2019-10",
                type: "增值税普票",
                taitou: "瑞士安美资源有限公司",
                total: null,
                tax: "5%",
                xiaotax: null,
                remark: ""
              }
            ]
          },
            {
              splitName: [
                { type: "seq", title: "序号", width: 50 },
                { field: "number", width: "100px", title: "发票号" },
                { field: "item", width: "150px", title: "开票项目", editRender: { name: "input" } },
                {
                  field: "time",
                  width: "150px",
                  title: "开票月份",
                  editRender: { name: "input", attrs: { type: "month" } }
                },
                { field: "type", width: "120px", title: "开票类型" },
                { field: "taitou", width: "200px", title: "发票抬头" },
                { field: "total", width: "100px", title: "票据总金额" },
                { field: "tax", width: "50px", title: "税率" },
                { field: "xiaotax", width: "50px", title: "销项税" },
                { field: "remark", width: "100px", title: "备注" }
              ],
              splitList: [
                {
                  number: "201901001",
                  item: "工资代收代付",
                  time: "2019-10",
                  type: "增值税普票",
                  taitou: "瑞士安美资源有限公司",
                  total: 12.5,
                  tax: "",
                  xiaotax: null,
                  remark: ""
                },
                {
                  number: "201901001",
                  item: "工资代收代付",
                  time: "2019-10",
                  type: "增值税普票",
                  taitou: "瑞士安美资源有限公司",
                  total: 20,
                  tax: "5%",
                  xiaotax: null,
                  remark: ""
                },
                {
                  number: "201901001",
                  item: "工资代收代付",
                  time: "2019-10",
                  type: "增值税普票",
                  taitou: "瑞士安美资源有限公司",
                  total: 0,
                  tax: "5%",
                  xiaotax: null,
                  remark: ""
                },
                {
                  number: "201901001",
                  item: "工资代收代付",
                  time: "2019-10",
                  type: "增值税普票",
                  taitou: "瑞士安美资源有限公司",
                  total: null,
                  tax: "5%",
                  xiaotax: null,
                  remark: ""
                }
              ]
            },
            {
              splitName: [
                { type: "index", title: "序号", width: 50 },
                { field: "number", width: "100px", title: "发票号" },
                { field: "item", width: "150px", title: "开票项目" },
                {
                  field: "time",
                  width: "150px",
                  title: "开票月份",
                  editRender: { name: "input", attrs: { type: "month" } }
                },
                { field: "type", width: "120px", title: "开票类型" },
                { field: "taitou", width: "200px", title: "发票抬头" },
                { field: "total", width: "100px", title: "票据总金额" },
                { field: "tax", width: "50px", title: "税率" },
                { field: "xiaotax", width: "50px", title: "销项税" },
                { field: "remark", width: "100px", title: "备注" },
                { title: "操作", width: "90px", showOverflow: true, fixed: "right", slots: { default: "operation" } }
              ],
              splitList: [
                {
                  number: "201901001",
                  item: "工资代收代付",
                  time: "2019-10",
                  type: "增值税普票",
                  taitou: "瑞士安美资源有限公司",
                  total: 12.5,
                  tax: "",
                  xiaotax: null,
                  remark: ""
                },
                {
                  number: "201901001",
                  item: "工资代收代付",
                  time: "2019-10",
                  type: "增值税普票",
                  taitou: "瑞士安美资源有限公司",
                  total: 20,
                  tax: "5%",
                  xiaotax: null,
                  remark: ""
                },
                {
                  number: "201901001",
                  item: "工资代收代付",
                  time: "2019-10",
                  type: "增值税普票",
                  taitou: "瑞士安美资源有限公司",
                  total: 0,
                  tax: "5%",
                  xiaotax: null,
                  remark: ""
                },
                {
                  number: "201901001",
                  item: "工资代收代付",
                  time: "2019-10",
                  type: "增值税普票",
                  taitou: "瑞士安美资源有限公司",
                  total: null,
                  tax: "5%",
                  xiaotax: null,
                  remark: ""
                }
              ]
            }]
        },
        // 对比
        invoiceTypes:[{id:'30300001',label:'服务费'},{id:'30300002',label:'或者什么费'}]
      };
    },
    methods: {
      // 打开发票拆分
      openInvoiceType() {
        this.invoiceOpen = true;
      },
      insertEvent() {
        this.$refs.invoiceTable.insertEvent();
      },
      //回到上一层
      backPrev() {
        this.invoiceSelect = true;
      },
      changeInvoiceType(n) {
        this.invoiceActive = n;
        console.log(this.invoiceActive);
        this.invoiceSelect = false;
      },
      // 对比两条数据例子
      compare() {
        // 原始数据
        const data1 = [{
          "invoiceMedium": "30310002",
          "amount": 3773.6,
          "operateMode": "20020001",
          "cusId": "1248138531219668994",
          "rate": 0.06,
          "invoiceTitleName": "中国（香港）沐思合伙有限公司北京代表处",
          "saleTax": 226.4,
          "invoiceType": "30300001",
          "invoiceTitleId": "1248218455527030785",
          "invoiceItemId": "30410001"
        }, {
          "invoiceMedium": "30310001",
          "amount": 27477.32,
          "operateMode": "20020001",
          "cusId": "1248138531219668994",
          "invoiceTitleName": "中国（香港）沐思合伙有限公司北京代表处",
          "invoiceType": "30300002",
          "invoiceTitleId": "1248218455527030785",
          "invoiceItemId": "30410002"
        }];
        // 修改后的数据
        const data2 = [{
          "invoiceMedium": "30310002",
          "amount": 73,
          "operateMode": "20020001",
          "cusId": "1248138531219668994",
          "rate": 0.06,
          "invoiceTitleName": "中国（香港）沐思合伙有限公司北京代表处",
          "saleTax": 226.4,
          "invoiceType": "30300001",
          "invoiceTitleId": "1248218455527030785",
          "invoiceItemId": "30410001"
        },{
            "invoiceMedium": "30310002",
            "amount": 3700,
            "operateMode": "20020001",
            "cusId": "1248138531219668994",
            "rate": 0.06,
            "invoiceTitleName": "中国（香港）沐思合伙有限公司北京代表处",
            "saleTax": 226.4,
            "invoiceType": "30300001",
            "invoiceTitleId": "1248218455527030785",
            "invoiceItemId": "30410001"
          }, {
          "invoiceMedium": "30310001",
          "amount": 27000,
          "operateMode": "20020001",
          "cusId": "1248138531219668994",
          "invoiceTitleName": "中国（香港）沐思合伙有限公司北京代表处",
          "invoiceType": "30300002",
          "invoiceTitleId": "1248218455527030785",
          "invoiceItemId": "30410002"
        },
          {
            "invoiceMedium": "30310001",
            "amount": 477.32,
            "operateMode": "20020001",
            "cusId": "1248138531219668994",
            "invoiceTitleName": "中国（香港）沐思合伙有限公司北京代表处",
            "invoiceType": "30300002",
            "invoiceTitleId": "1248218455527030785",
            "invoiceItemId": "30410002"
          }];

        // 分别计算两条数据的各类型的总和
        const split1 = this.splitData(data1);
        const split2 = this.splitData(data2);

        // arr记录错误的type
        let errorArr = [];
        for(var key in split1){
         if(split1[key] != split2[key]){
           errorArr.push(key)
         }
        }

        for(var i = 0;i < errorArr.length;i++){
          for(var j = 0;j < this.invoiceTypes.length;j++) {
            if (this.invoiceTypes[j].id == errorArr[i]) {
              errorArr[i] = this.invoiceTypes[j].label;
            }
          }
        }
        if(errorArr&&errorArr.length > 0){
          this.$message.error(errorArr.toString()+"数据不能对应")
        }else{
          this.$message.success("数据对应")
        }
      },
      splitData(temp){
        let splitObj={};
        let amountObj = {};
        for(var i = 0;i < temp.length;i++){
          if(splitObj[temp[i].invoiceType]){
            splitObj[temp[i].invoiceType].push(temp[i].amount);
          }else{
            let obj = {};
            obj[temp[i].invoiceType] = [temp[i].amount];
            splitObj = Object.assign(splitObj,obj)
          }
        }
        for(var key in splitObj){
          amountObj[key] = floatSum(splitObj[key])
        }
        return amountObj
      }
    }
  };
</script>

<style scoped lang="scss">

  .dialogTitle {
    display: flex;
  }

  .tancengTitle {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .backBtn {
    margin-top: 3px;
  }
</style>
