<template>
    <div >
      <div class="invoiceTypeTitle">
        <div class="invoiceTypeName">{{invoiceTypeName}}</div>
        <p class="invoiceCompanyName">{{invoiceCompanyName}}</p>
      </div>
      <div class="invoiceLists" >
        <div class="invoiceList">
          <div class="invoiceCount">
            <span>{{invoiceCompanyData.companyNo}}</span>
            <span>账单编号</span>
          </div>
          <div class="invoiceCount">
            <span>{{invoiceCompanyData.companyCount}}</span>
            <span>发票张数</span>
          </div>
          <div class="invoiceCount">
            <span>{{invoiceCompanyData.companyWage}}</span>
            <span>工资代收代付</span>
          </div>
          <div class="invoiceCount">
            <span>{{invoiceCompanyData.companyService}}</span>
            <span>服务费</span>
          </div>
          <div class="invoiceCount">
            <span>{{invoiceCompanyData.companySales}}</span>
            <span>销项税额</span>
          </div>
        </div>
        <div class="invoiceListBtn" v-if="invoiceActive === 3">
          <el-button type="primary" size="small"  @click="insertEvent()">增加</el-button>
        </div>
      </div>
    </div>
</template>

<script>
  export default {
    name: "invoiceTop",
    props:{
      invoiceTypeName:{
        type: String,
        default: ''
      },
      invoiceCompanyName:{
        type: String,
        default: ''
      },
      invoiceCompanyData:{
        type:Object,
        default:() => {
          return {}
        }
      },
      invoiceActive:{
        type:Number,
        default:1
      },
    },
    data(){
      return{

      }
    },
    methods: {
      insertEvent(){
        this.$emit("insertEvent")
      }
    }
  };
</script>

<style scoped lang="scss">
  .invoiceTypeTitle{
    display: flex;
    .invoiceTypeName{
      width: 96px;
      height: 36px;
      color: #ffffff;
      font-size: 12px;
      padding-left: 16px;
      margin-right: 10px;
      line-height: 34px;
      background: url("~@/assets/img/form/invoiceName.png");
    }
    .invoiceCompanyName{
      color:#000;
      font-size: 16px;
      margin-top: 1px;
      line-height: 36px;
    }
  }
  .invoiceLists{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  .invoiceList{
    display: flex;
    flex:1;
  .invoiceCount{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex:1;
    position: relative;
    margin: 10px 0;
  &::before{
     content:"";
     position: absolute;
     right:0;
     top:50%;
     margin-top: -15px;
     height: 30px;
     width: 1px;
     background:#ccc;
   }
  &:last-child::before{
     display: none;
   }
  span{
    color:#000;
    font-size: 16px;
    line-height: 26px;
  }
  span+span{
    color:#999;
    font-size: 12px;
    line-height: 22px;
  }
  }
  }
  .invoiceListBtn{
    margin-left: 10px;
  .el-button{
    width: 53px;
  }
  }
  }
</style>
