<template>
  <div class="defaultInvoice">
    <vxe-grid
      class="invoiceTable"
      ref="refname"
      resizable
      show-footer
      show-footer-overflow
      height="300"
      :footer-method="footerMethod"
      :columns="names"
      :data="datas"
      :edit-config="{trigger: 'click', mode: 'row', showStatus: true}"
      :loading="loading"
      keep-source
    >
      <template v-slot:operation="{ row }" v-if="invoiceActive === 3">
        <el-button type="text"   @click="deleteData(row)">删除</el-button>
      </template>
    </vxe-grid>
    <div class="tancengBtn">
      <el-button type="primary" size="small" @click="submitData">确定</el-button>
      <el-button type="" size="small" @click="backPrev">取消</el-button>
    </div>
  </div>
</template>

<script>
  export default {
    name: "defaultInvoice",
    props:{
      invoiceActive:{
        type:Number,
        default:1
      },
      sumItem:{
        type: Array,
        default () {
          return []
        }
      },
      names:{
        type: Array,
        default () {
          return []
        }
      },
      datas: {
        type: Array,
        default () {
          return []
        }
      },
      refname: {
        type: String,
        default: ''
      }
    },
    data(){
      return{
      }
    },
    created(){
      // 空值格式化
      this.names.map((item) => {
        item.formatter = this.formatEmpty;
        return item
      })

    },
    mounted(){
      const datas = this.datas.filter(item => item.total&&item.tax )
      datas.map((column) => {
        column.xiaotax = column.total*parseFloat(column.tax)/100
        return column
      })
    },
    methods: {
      insertEvent(row){
        this.$refs.refname.insertAt({}, row)
      },
      submitData(){
        this.datas = this.$refs.refname.getInsertRecords().concat(this.datas)
        console.log("数据",this.datas)
      },
      footerMethod ({ columns, data }) {
        return [
          columns.map((column, columnIndex) => {
            if (columnIndex === 0) {
              return '合计'
            }
            if (this.sumItem.includes(column.property)) {
              return this.$utils.sum(data, column.property)
            }
            return null
          })
        ]
      },
      deleteData(row){
        var that = this;
        this.$confirm('您确定要删除该数据?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
            that.$refs.refname.remove(row)
            that.$message.success('删除成功!')

        }).catch(() => {
        });
      },
      backPrev(){
        this.$emit('backPrev')
      },
      // 格式化空值
      formatEmpty({ cellValue }){
        if(cellValue){
          return cellValue
        }else{
          return "--"
        }
      },
      // 格式化空值
      // formatXiaotax({ cellValue, row, column }){
      //   if(row.total&&row.tax){
      //     return row.total*parseFloat(row.tax)/100
      //   }else{
      //     return "--"
      //   }
      // },
    }
  };
</script>

<style scoped lang="scss">

  .invoiceTable{
    box-shadow:0px 1px 7px 0px rgba(0,0,0,0.1);
    border-radius: 5px;
    background: #fff;
    padding: 14px 20px;
    .vxe-table .vxe-body--column:not(.col--ellipsis),
    .vxe-table .vxe-footer--column:not(.col--ellipsis),
    .vxe-table .vxe-header--column:not(.col--ellipsis){
      padding: 0;
    }
    .vxe-table--filter-wrapper .vxe-default-input,
    .vxe-table--filter-wrapper .vxe-default-select,
    .vxe-table--filter-wrapper .vxe-default-textarea,
    .vxe-table .vxe-default-input,
    .vxe-table .vxe-default-select,
    .vxe-table .vxe-default-textarea{
      height: 26px;
      padding: 0 6px;
      color:#000;
      font-size: 12px;
    }
  }
</style>
