<template>
  <div class="invoiceSelect">
    <div class="tancengContent">
      <div class="invoicesBox">
        <div class="invoice" :class="{active:invoiceActive === 1}" @click="changeInvoiceType(1)">
          <div class="invoiceIcon">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-shujuxiaoyan_A"></use>
            </svg>
          </div>
          <span>默认发票抬头</span>
          <p>
            按客户下指定的默认抬头开票
          </p>
        </div>
        <div class="invoice" :class="{active:invoiceActive === 2}" @click="changeInvoiceType(2)">
          <div class="invoiceIcon">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-shujuxiaoyan_A"></use>
            </svg>
          </div>
          <span>成本中心</span>
          <p>
            按雇员所属成本中心拆分
          </p>
        </div>
        <div class="invoice" :class="{active:invoiceActive === 3}" @click="changeInvoiceType(3)">
          <div class="invoiceIcon">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-shujuxiaoyan_A"></use>
            </svg>
          </div>
          <span>人工拆分</span>
          <p>
            可随意按客户需求进行拆分
          </p>
        </div>
      </div>
      <div class="invoicesTip">
        <div class="invoicesTitle">
          <div class="invoiceTitleIcon">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-tishi"></use>
            </svg>
          </div>
          <span>温馨提醒</span>
        </div>
        <div class="invoicesContent">
          <p>
            1、除多抬头拆分和人工拆分方式外，单张发票金额超过100万后，系统自动进行拆票
          </p>
          <p>
            2、默认发票抬头和成本中心拆分开票金额自动汇总，其他方式均需人工干预
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "invoiceType",
    props:{
      invoiceActive:{
        type:Number,
        defalult:1
      }
    },
    methods:{
      //发票
      changeInvoiceType(n){
        this.$emit("changeInvoiceType",n);

      },
    }
  };
</script>

<style scoped lang="scss">
  .invoicesBox{
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    .invoice{
      width:150px;
      height:190px;
      padding:30px 20px 20px;
      box-sizing: border-box;
      border:1px solid #ccc;
      border-radius:4px;
      display: flex;
      align-items: center;
      flex-direction: column;
      cursor: pointer;
      &.active{
        transform: scale(1.05);
        box-shadow:0px 1px 7px 0px rgba(0, 0, 0, 0.1);
        background: rgba(255,255,255,.5);
      }
      &:hover{
        transform: scale(1.05);
        background: rgba(255,255,255,.2);
      }
      .invoiceIcon{
        width: 68px;
        height: 68px;
      }
      .icon{
        font-size: 68px;
      }
      span{
        color:#000;
        font-size: 16px;
      }
      p{
        font-size: 12px;
        color: #000;
        text-align: center;
      }
    }
  }
  .invoicesTitle {
    display: flex;
    line-height: 24px;
    margin-top: 20px;
    font-size: 14px;
    color: #F2A500;
    .invoiceTitleIcon {
      width: 16px;
      height: 16px;
      margin-right: 10px;
      .icon {
        font-size: 16px;
        color: #F19E00;
      }
    }
  }
  .invoicesContent {
    p {
      font-size: 12px;
      line-height: 20px;
      text-indent: -20px;
      padding-left: 24px;
    }
  }
  .tancengBtn{
    margin-top: 30px;
    text-align: center;
    .el-button--small{
      width: 80px;
      height: 30px;
    }
  }
</style>
